<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";

@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
.w20 {
  width: 20%;
}
/* .w20:last-child {
  width: 19%;
} */
.w33 {
  width: 33.33%;
}
/* .w33:last-child {
  width: 34%;
} */
.w50 {
  width: 50%;
}

.w66 {
  width: 66.66%;
}

.w100 {
  width: 100%;
}

.form-select {
  padding: 3px;
}

.msgContent {
  border: unset;
}

.msgLabel {
  /* border: 1px solid #dfdfdf; */
}
.msgLabel.msgtextarea .msgVal {
  display: flex !important;
}

.msgVal {
  padding: 0 10px;
}
.upload-demo {
  display: flex;
  align-items: center;
}
/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}

.guding-img {
  width: 33.33%;
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfdfdf;
}
</style>
<style type="text/css" scoped>
/deep/ .selectShow [readOnly] {
  border: none;
}
.selectShow :disabled {
  border: none;
}
.custom-file-label::after {
  display: none;
}
.el-form-item {
  margin-bottom: unset;
  width: 100%;
}

.msgtextarea .msgVal {
  display: block;
}

/deep/.el-input__inner {
  background-color: #fff9eb;
  width: 100%;
  height: 30px;
  background: unset;
}

/deep/ .el-textarea__inner {
  background-color: #fff9eb;
}

/deep/.el-form-item__content {
  line-height: inherit;
}

.el-form-item--small .el-form-item__error {
  margin-top: 2px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

/deep/.el-form-item__error {
  position: absolute !important;
  top: 20%;
  left: 60%;
  text-align: center;
  width: 120px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  z-index: 99;
}

.el-radio {
  margin-bottom: 0;
}
.custom-file-label::after {
  display: none;
}
.msgLabel {
  height: 45px;
}
.personPic {
  height: 270px;
}
.pay-box img {
  width: 56px;
  height: 56px;
}
.border-b {
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
.ksbz {
  border: 1px dashed #dfdfdf;
  padding: 5px;
  min-height: 50px;
  white-space: pre-wrap;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import FormHeader from "@/views/pages/examinee/form-header.vue";
import draggable from "vuedraggable";
import yzFormItem from "@/components/form/yzFormItem.vue";

import {
  getExamForm,
  getUnit,
  getPosition,
  getSubject,
  getExamInfo,
  examApply,
  examEdit,
  getUserExamOne,
  getUserBmk,
  examPay,
  getXK,
  getjfzt,
  getbmkskm,
  userSpecial,
  getExclusive, deleteApply,
} from "@/api/examination.js";
import {
  applyRegisterFeeApply,
  reapplyRegisterFee,
  getRegisterFeeApplyBybmh,
} from "@/api/freeApply";
import { getUserEdu } from "@/api/userInfo.js";
import { checkFile, unitsChange } from "@/libs/common";
import { covertToForm } from "@/api/common/draggable.js";
import { uploadFiles, getUploadLimit } from "@/api/index";
import examForm from "@/components/form/examForm";
import registerInfo from "@/components/examInfo/registerInfo";
import freeExam from "@/components/examInfo/freeExam";

/**
 * 报名信息提交
 */
export default {
  components: {
    Layout,
    PageHeader,
    FormHeader,
    draggable,
    yzFormItem,
    examForm,
    registerInfo,
    freeExam,
  },
  data() {
    return {
      title: "报名信息提交",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "信息提交",
          active: true,
        },
      ],
      pageNum: 1,
      isPay: false,
      canPay: false,
      userInfo: {},
      zfData: {
        zffs: "01",
        data: "",
      },
      eduList: [],
      query: {},
      examInfo: {},
      derateData:{},
      derateFrom: {
        jmyy: "",
        jmxs: "",
        jmfj: "",
        jmfjmc: "",
        jmfjdx: "",
      },
      kqxzform: {
        bkgwxkm: "",
        bkgwxk: "",
        areaSid: "",
        bkdwzym: "",
        bkdwzy: "",
      },
      form: {
        gznxzm: "",
        qxdm: "",
        hjszdm: "",
      },
      zfModal: false,
      readOnly: false,
      astrict: {
        zpdz: {},
        xlzm: {},
        gznxzm: {},
        jmfj: {},
      },
      interval: "",
      dicts: [],
      bmkmList: [],
      specialData: {}, //特殊库信息
    };
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    quickPay() {
      let _this = this;
      let obj = {
        bmh: this.form.bmh,
        zffs: this.zfData.zffs,
      };
      examPay(obj).then((res) => {
        if (res.status) {
          _this.zfData.data = res.data;
          if (this.zfData.zffs == "02") {
            const div = document.createElement("div");
            div.setAttribute("id", "zfbForm");
            div.innerHTML = res.data.result;
            document.body.insertBefore(div, document.body.children[0]);
            document.forms[0].setAttribute("target", "_blank");
            document.forms[0].submit();
          } else if (this.zfData.zffs == "01") {
            _this.zfModal = true;
          } else {
            let url = res.data.result;
            let a = document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("target", "_blank");

            document.body.appendChild(a);
            a.click();
          }
          _this.$confirm("是否已经支付成功？若您已经支付成功请点击确定即可。", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                  getjfzt({
                    outTradeNo: res.data.trade.ddh,
                    shbm: res.data.trade.shbm,
                    zffs: _this.zfData.zffs,
                  }).then((res) => {
                    if (res.status) {
                        if (res.data.tradeStatus) {
                          _this.zfModal = false;
                          _this.$router.replace({
                            path: "./record",
                          });
                      }
                    }
                  });
              })
              .catch(()=> {
                getjfzt({
                  outTradeNo: res.data.trade.ddh,
                  shbm: res.data.trade.shbm,
                  zffs: _this.zfData.zffs,
                }).then((res) => {
                  if (res.status) {
                    if (res.data.tradeStatus) {
                      _this.zfModal = false;
                      _this.$router.replace({
                        path: "./record",
                      });
                    }
                  }
                });
              });
          // let roundNum = 120
          // _this.interval = setInterval(function () {
          //   roundNum--;
          //   if (roundNum == 0) {
          //
          //     clearInterval(this.interval);
          //     this.interval = null;
          //   }
          //   getjfzt({
          //     outTradeNo: res.data.trade.ddh,
          //     shbm: res.data.trade.shbm,
          //     zffs: _this.zfData.zffs,
          //   }).then((res) => {
          //     if (res.status) {
          //         if (res.data.tradeStatus) {
          //           _this.zfModal = false;
          //           _this.$router.replace({
          //             path: "./recode",
          //           });
          //       }
          //     }
          //   });
          // }, 5000);
        }
      });
    },
    save() {
      let _this = this;
      if (_this.form.bmh) {
        examEdit(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
            _this.$message({ type: "success", message: res.message });
          }
        });
      } else {
        examApply(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
          }
        });
      }
    },

    submitFrom() {
      let _this = this;
      _this.form.sfzstj = true;
      if (_this.form.bmh) {
        examEdit(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
            _this.$message({ type: "success", message: res.message });
          }
        });
      } else {
        examApply(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
            _this.$message({ type: "success", message: res.message });
          }
        });
      }
    },
    //获取考试详情
    getExamInfo() {
      getExamInfo(this.query.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = res.data;
          if (this.examInfo.sfqyzymdk) {
            this.getSpecial();
          } else {
            this.init(this.query.bmh);
          }
        }
      });
    },
    //获取文件限制
    getLimit() {
      getUploadLimit({ module: "WorkCertificate" }).then((res) => {
        if (res.status) {
          this.astrict.gznxzm["ext"] =
            "." + res.data.extLimit.replaceAll(",", ",.");
          this.astrict.gznxzm["size"] = res.data.sizeLimit;
          let size = unitsChange(res.data.sizeLimit);
          this.astrict.gznxzm["text"] =
            "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
        }
      });
    },
    //上传文件
    uploadFile(file, key) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      let result = checkFile(file, this.astrict[key]);

      if (result.status) {
        if (key == "jmfj") {
          fileRule = "WorkCertificate";
        } else if (key == "gznxzm") {
          fileRule = "WorkCertificate";
        }
        uploadFiles(fileRule, file, _this.examInfo.ksbmbh).then((res) => {
          if (res.status) {
            let resFile = res.data[0];
            _this.$message({ type: "success", message: "上传成功" });
            if (key == "jmfj") {
              _this.derateFrom[key] = resFile.fileId;
              _this.derateFrom["jmfjmc"] = file.name;
              _this.derateFrom["jmfjdx"] = file.size;
              return;
            }
            _this.form[key] = resFile.fileId;
          }
        });
      } else {
        _this.$message({ type: "error", message: result.message });
      }
      this.$refs[key].clearFiles();
      return false;
    },
    //缴费减免申请
    derateApply() {
      let _this = this;
      console.log(this.derateFrom);
      for (const key in this.derateFrom) {
        if (!this.derateFrom[key]) {
          this.$message("信息不完整，请继续填写");
          return;
        }
      }
      if (this.form.jmzt == 2) {
        this.derateFrom.sid = this.derateData.sid
        reapplyRegisterFee(this.form.bmh, this.derateFrom).then((res) => {
          if (res.status) {
            this.$refs["derateModal"].hide();
            this.form.jmzt = 0;
            this.$message({
              message: "缴费减免申请已提交，可等待管理员审核后再继续缴费",
              type: "success",
            });
            //查询减免申请
            getRegisterFeeApplyBybmh(_this.form.bmh).then((res) => {
              if (res.status) {
                this.derateFrom = res.data;
              }
            });
          }
        });

        return;
      }
      applyRegisterFeeApply(this.form.bmh, this.derateFrom).then((res) => {
        if (res.status) {
          this.$refs["derateModal"].hide();
          this.form.jmzt = 0;
          this.$message({
            message: "缴费减免申请已提交，可等待管理员审核后再继续缴费",
            type: "success",
          });
          //查询减免申请
          getRegisterFeeApplyBybmh(_this.form.bmh).then((res) => {
            if (res.status) {
              this.derateFrom = res.data;
            }
          });
        }
      });
    },
    init(bmh) {
      getUserExamOne(bmh + "?isShowId=1").then((res) => {
        if (res.status) {
          let data = res.data[0] || "";
          if (data) {
            if ((data.jfzt == 1 || data.jfzt == 2) && data.zgshzt == 1) {
              this.$router.replace({
                path: "./record",
              });
              return;
            }
            if (data.jmzt != -1 && data.jmzt != null) {
              //查询减免申请
              getRegisterFeeApplyBybmh(data.bmh).then((res) => {
                if (res.status) {
                  if (res.data) {
                    this.derateData = res.data
                    for (const key in this.derateFrom) {
                      this.derateFrom[key] = res.data[key]
                    }
                  }
                }
              });
            }
            let newTime = new Date().getTime();
            if (data.zgshzt == 1) {
              this.pageNum = 3;
              this.readOnly = true;
              if (
                this.examInfo.jfkssj &&
                this.examInfo.jfjssj &&
                new Date(this.examInfo.jfkssj.replace(/-/g, "/")).getTime() <
                  newTime &&
                newTime <
                  new Date(this.examInfo.jfjssj.replace(/-/g, "/")).getTime()
              ) {
                this.canPay = true;
              }
              this.items[1].text = "报名缴费";
              this.isPay = true;
              getUploadLimit({ module: "RegisterFeeApply" }).then((res) => {
                if (res.status) {
                  this.astrict.jmfj["ext"] =
                    "." + res.data.extLimit.replaceAll(",", ",.");
                  this.astrict.jmfj["size"] = res.data.sizeLimit;
                  let size = unitsChange(res.data.sizeLimit);
                  this.astrict.jmfj["text"] =
                    "只支持" +
                    res.data.extLimit +
                    "，类型的文件，且不超过" +
                    size;
                }
              });
            } else if (
              newTime >
              new Date(this.examInfo.bmjssj.replace(/-/g, "/")).getTime()
            ) {
              this.pageNum = 2;
              this.readOnly = true;
              this.items[1].text = "资格审核";
            } else {
              this.pageNum = 1;
              this.items[1].text = "报名提交";
              this.readOnly = false;
            }
            if (this.specialData.json) {
              let obj = JSON.parse(this.specialData.json);
              for (const key in obj) {
                if (obj[key]) {
                  data[key] = obj[key];
                }
              }
            }

            this.form = data;
            // if (this.examInfo.bmkbm) {
              getUserBmk(this.query.ksbmbh).then((res) => {
                if (res.status) {
                  let obj = res.data[0] || {};
                  for (const key in obj) {
                    if (!data[key]) {
                      data[key] = obj[key];
                    }
                  }
                  this.form = data;
                }
              });
            // } else {
            //   this.form = data;
            // }
          }
        }
      });
    },
    //查询报名库
    getBmk() {
      getUserBmk(this.query.ksbmbh).then((res) => {
        if (res.status) {
          let obj = res.data[0] || {};
          for (const key in obj) {
            if (!this.form[key]) {
              this.form[key] = obj[key];
            }
          }
        }
      });
    },
    //查询特殊库
    getSpecial() {
      getExclusive(this.examInfo.ksbmbh).then((res) => {
        if (res.status) {
          let list = res.data || [];
          if (list && list.length) {
            let tsData = list[0];
            this.specialData = tsData;
          }
          this.init(this.query.bmh);
        }
      });
    },
    filePriview(){
      this.$yzImgDialog().show(this.getFileUrl(this.derateFrom.jmfj))
    }
  },
  created() {
    this.query = this.$route.query;
    this.getExamInfo();
    getbmkskm(this.query.bmh).then((res) => {
      if (res.status) {
        this.bmkmList = res.data;
      }
    });
  },
  watch: {
    zfModal: {
      handler(val) {
        if (val == false) {
          clearInterval(this.interval);
          this.interval = "";
        }
      },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <FormHeader :pageNum="pageNum" :examInfo="examInfo" />
            <div>
              <div class="message-tips mt-3" v-if="isPay">
                <img
                  src="@/assets/images/person/fa-check-circle.png"
                  class="mr-2"
                />
                <span class="green-font"
                  >请再次核对您的报名资料，确认无误后尽快在规定时间内完成缴费。</span
                >
              </div>
              <div
                class="outerCotent person-form mt-3 border-b"
                v-if="form.kmxzfs == 1"
              >
                <registerInfo
                  v-model="form"
                  v-if="examInfo.kslxmc != '其他申请报名'"
                ></registerInfo>
                <freeExam
                  v-if="form.mssqzt != '-1' && form.mssqzt != null"
                  v-model="form"
                ></freeExam>
              </div>

              <div v-if="examInfo.kstsxx" class="ksbz mt-3">
                <div>{{ examInfo.kstsxx }}</div>
              </div>
              <div class="outerCotent mt-3 person-form" v-loading="!form.bmh">
                <examForm
                  v-model="form"
                  :examInfo="examInfo"
                  :bmh="form.bmh"
                  :ksbmbh="query.ksbmbh"
                  :readOnly="readOnly"
                  :specialData="specialData"
                  :isShowYJ="true"
                  @save="save"
                  @submit="submitFrom"
                ></examForm>
              </div>
              <div v-if="examInfo.ksbzxx" class="ksbz mt-3">
                <div>{{ examInfo.ksbzxx }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="isPay && canPay">
      <div class="card-body">
        <div class="pay-money flexList">
          <div class="mr-4">报名费用总额</div>
          <span class="red-font">{{ form.yjfje / 100 }}元</span>
          <div class="d-flex ml-5" v-if="examInfo.sfjmks">
            <el-button v-b-modal.derateModal size="small" v-if="form.jmzt == -1"
              >缴费减免申请</el-button
            >
            <div
              style="margin-left: 10px"
              slot="tip"
              class="el-upload__tip"
              v-if="form.jmzt != -1 && form.jmzt != null"
            >
              <a href="javascript:;" v-b-modal.derateModal>
                已申请减免，
                <span
                  :class="{
                    'text-info': form.jmzt == 0,
                    'text-success': form.jmzt == 1,
                    'text-danger': form.jmzt == 2,
                  }"
                  >{{
                    form.jmzt == 0
                      ? "未审核"
                      : form.jmzt == 1
                      ? "已通过"
                      : form.jmzt == 2
                      ? "未通过"
                      : ""
                  }}</span
                >
                ，查看申请
              </a>
            </div>
            <b-modal
              id="derateModal"
              ref="derateModal"
              title="提交缴费减免申请"
              title-class="font-18"
              centered
              class="person-modal"
              hide-footer
              style="width: 500px"
            >
              <el-form ref="derateFrom" :model="derateFrom" label-width="80px">
                <el-form-item label="申请理由" class="mb-2">
                  <el-input
                    type="textarea"
                    :readonly="form.jmzt != -1 && form.jmzt != 2"
                    placeholder="请输入申请减免理由（不超过50）"
                    rows="2"
                    maxlength="50"
                    v-model="derateFrom.jmyy"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="申请详述" class="mb-2">
                  <el-input
                    type="textarea"
                    :readonly="form.jmzt != -1 && form.jmzt != 2"
                    placeholder="请输入申请减免理由（不超过500）"
                    rows="3"
                    maxlength="500"
                    v-model="derateFrom.jmxs"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="申请材料" class="mb-2">
                  <el-upload
                    class="upload-demo"
                    action=""
                    ref="jmfj"
                    :auto-upload="false"
                    :limit="1"
                    :show-file-list="false"
                    :accept="astrict.jmfj.ext"
                    :on-change="
                      (file) => {
                        return uploadFile(file, 'jmfj');
                      }
                    "
                  >
                    <el-button
                      size="small"
                      v-if="
                        form.jmzt == -1 || form.jmzt == null || form.jmzt == 2
                      "
                      ><i
                        class="iconfont icon-tongyong_2_icontubiao_fill_upload mr-2"
                      ></i
                      >上传申请减免缴费材料</el-button
                    >
                    <div
                      style="margin-left: 10px"
                      slot="tip"
                      class="el-upload__tip"
                    >
                      <a
                        v-if="derateFrom.jmfj"
                        @click="
                         filePriview
                        "
                        href="javascript:;"
                        >查看申请材料</a
                      >
                      <span v-else>{{ astrict.jmfj.text }}</span>
                    </div>
                  </el-upload>
                </el-form-item>
                <el-form-item
                  label="审核说明"
                  class="mb-2 flexList"
                  v-if="form.jmzt != -1 && form.jmzt != 0"
                >
                  <div class="mb-2">{{ derateData.shsm }}</div>
                </el-form-item>
                <el-form-item
                  class="mb-2 text-center"
                  v-if="form.jmzt == -1 || form.jmzt == null || form.jmzt == 2"
                >
                  <el-button
                    type="button"
                    @click="derateApply"
                    v-if="form.jmzt == -1 || form.jmzt == null"
                    >提交申请</el-button
                  >
                  <el-button
                    type="button"
                    @click="derateApply"
                    v-if="form.jmzt == 2"
                    >重新提交</el-button
                  >
                </el-form-item>
                <el-form-item class="mb-2 text-danger">
                  提交申请减免后请耐心等待审核结果，在审核期间请勿缴费
                </el-form-item>
              </el-form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isPay && canPay">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3">请选择支付方式</h4>
            <div class="pay-container p-3">
              <div class="mb-3 d-flex">
                <div
                  class="pay-box flexList justify-content-center"
                  :class="{ active: zfData.zffs == '01' }"
                  v-if="examInfo.sffssz[0] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      value="01"
                      class="custom-control-input"
                      v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio1"
                      >微信支付</label
                    >
                  </div>
                  <img
                    src="@/assets/images/person/antOutline-wechat.png"
                    alt=""
                  />
                </div>
                <div
                  class="pay-box flexList justify-content-center"
                  :class="{ active: zfData.zffs == '02' }"
                  v-if="examInfo.sffssz[1] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      value="02"
                      class="custom-control-input"
                      v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio2"
                      >支付宝</label
                    >
                  </div>
                  <img
                    src="@/assets/images/person/antOutline-alipay-circle.png"
                    alt=""
                  />
                </div>
                <div
                  class="pay-box flexList justify-content-center"
                  :class="{ active: zfData.zffs == '03' }"
                  v-if="examInfo.sffssz[2] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                      type="radio"
                      id="customRadio3"
                      name="customRadio"
                      value="03"
                      class="custom-control-input"
                      v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio3"
                      >易宝</label
                    >
                  </div>
                  <img src="@/assets/images/person/csc-logo.png" alt="" />
                </div>


                <div
                    class="pay-box flexList justify-content-center"
                    :class="{ active: zfData.zffs == '07' }"
                    v-if="examInfo.sffssz[3] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                        type="radio"
                        id="customRadio4"
                        name="customRadio"
                        value="07"
                        class="custom-control-input"
                        v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio4"
                    >合利宝</label
                    >
                  </div>
                  <img src="@/assets/images/person/helibao.png" alt="" style="    width: auto; height: 30px;" />
                </div>


              </div>
              <button
                type="button"
                class="btn btn-info pay-btn"
                @click="quickPay"
              >
                立即支付
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="zfModal"
      v-model="zfModal"
      centered
      :title="zfData.zffs == '02' ? '支付宝支付' : '微信支付'"
      size="lg"
      title-class="font-18"
      hide-footer
      style="min-height: 300px"
      class="d-flex justify-content-center align-items-center"
    >
      <div v-if="zfModal">
        <div
          v-if="zfData.zffs == '01'"
          class="wxzf d-flex flex-column align-items-center justify-content-center"
          style="min-height: 300px"
        >
          <div>缴费项目：{{ zfData.data.trade.ddmc }}</div>
          <div>收费单位：{{ zfData.data.trade.shmc }}</div>
          <div class="text-warning">缴费金额：{{ form.yjfje / 100 }}元</div>
          <img
            :src="zfData.data.result"
            alt=""
            v-if="zfData.zffs == '01'"
            style="width: 150px"
          />
          <div>微信扫描支付</div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
