import {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
} from "@/libs/axios";

// 用户
// 减免列表
let base = '/yethan/registerFeeApply'
export const getRegisterFeeApplyListPage = function (params) {
    return getRequest(`${base}/listPage`, params)
}
// 删除减免
export const deleteRegisterFeeApply = function (id) {
    return deleteRequest(`${base}/${id}`)
}
// 获取详情/sid
export const getRegisterFeeApplyBysid = function (id) {
    return getRequest(`${base}/${id}`)
}// 获取详情/bmh
export const getRegisterFeeApplyBybmh = function (bmh) {
    return getRequest(`${base}/bmh/${bmh}`)
}
// 减免申请
export const applyRegisterFeeApply = function (bmh, params) {
    return postRequest(`${base}/${bmh}`, params)
}
// 减免申请重新提交
export const reapplyRegisterFee = function (bmh, params) {
    return putRequest(`${base}/${bmh}`, params)
}